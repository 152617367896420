import styled from 'styled-components'

export const P = styled.p`
  line-height: 1.5;
  font-size: 2.5rem;
  text-align: justify;
`

export const Timer = styled.p`
  line-height: 1.5;
  font-size: 2.5rem;
  text-align: center;
  font-variant-numeric: tabular-nums;
`

export const Container = styled.div`
  max-width: 80vw;
`

export const ImageContainer = styled.div`
  max-width: 40vw;
  margin: 0 auto;
`
