/**
 * Returns a string with the appropriate time period (singular or plural) based on the given number of time units
 * @param {number} timeUnits - The number of time units (e.g. days, hours, minutes, seconds)
 * @param {string} unitName - The time period (e.g. day, hour, minute, second)
 * @returns {string} - The formatted string with the time period
 */
function formatTimeUnits(timeUnits, unitName) {
  // If there is no time left, return an empty string
  if (timeUnits === 0) return "";
  // If there is only one time unit, return a singular string, else if there are multiple time units, return a plural string
  return `${timeUnits} ${unitName}${timeUnits === 1 ? '' : 's'}`;
}

/**
 * Returns the appropriate punctuation (comma, ampersand, or nothing) between two time units.
 * @param {number} prev - The value of the previous time unit.
 * @param {number[]} timeUnitsArr - An array of values of the subsequent time units.
 * @param {boolean} onlyOneNonZero - Whether only one of the time units is not zero.
 * @returns {string} The appropriate punctuation between the time units.
 */
function getCommaAmpersandOrNothing(prev, timeUnitsArr, onlyOneNonZero) {
  // If everything is 0, or if only one of them is not 0, return an empty string
  if (timeUnitsArr.every(i => i === 0) || onlyOneNonZero) return '';
  // If everything else beyond the next one is 0, return an ampersand
  if (timeUnitsArr.slice(1).every(i => i === 0)) return ' & ';
  // If the previous one isn't 0, return a comma, otherwise, return an empty string
  return prev !== 0 ? ', ' : '';
}

/**
 * Calculates the countdown sentence based on the time left until the target date.
 * @param {number} days - The number of days left.
 * @param {number} hours - The number of hours left.
 * @param {number} mins - The number of minutes left.
 * @param {number} secs - The number of seconds left.
 * @returns {string} The countdown sentence.
 */
function calcCountdownSentence(days, hours, mins, secs) {
  // Check if only one of the values are 0
  const onlyOneNonZero = ((params) => {
    const timeUnitsArr = Object.keys(params).map((k) => params[k])
    let nonZeroCount = 0
    for (let i = 0; i < timeUnitsArr.length; i++) {
      if (timeUnitsArr[i] !== 0) nonZeroCount++
      if (nonZeroCount > 1) break
    }
    return nonZeroCount === 1
  })({ days, hours, mins, secs })

  // Build the countdown sentence by concatenating the appropriate time periods and punctuation
  return (formatTimeUnits(days, "day") +
  getCommaAmpersandOrNothing(days, [hours, mins, secs], onlyOneNonZero)
  + formatTimeUnits(hours, "hour")
  + getCommaAmpersandOrNothing(hours, [mins, secs], onlyOneNonZero)
  + formatTimeUnits(mins, "min")
  + getCommaAmpersandOrNothing(mins, [secs], onlyOneNonZero)
  + formatTimeUnits(secs, "sec")).replace(",  &", " &")
}

/**
 * Calculates the countdown to a wedding date in days, hours, minutes, and seconds.
 * @param {string|Date} date1 - The current date, in string or Date object format.
 * @param {string|Date} date2 - The wedding date, in string or Date object format.
 * @returns {string} - A string describing the wedding countdown, depending on the time difference between the current date and the wedding date.
 */
export default function calculateWeddingCountdown(date1, date2) {
  // Convert the dates to Date objects
  const now = new Date(date1);
  const weddingDate = new Date(date2);

  // Calculate the difference between the two dates
  const difference = weddingDate - now;

  // Calculate the number of days, hours, minutes and seconds between the two dates
  const days = Math.trunc(difference / (1000 * 60 * 60 * 24));
  const hours = Math.round((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const mins = Math.round((difference % (1000 * 60 * 60)) / (1000 * 60));
  const secs = Math.round((difference % (1000 * 60)) / 1000);

  // Return a string indicating the countdown or the married status
  if (difference == 0) return "Married";
  if (difference > 0) return "Married in " + calcCountdownSentence(days, hours, mins, secs);
  return "Married for " + calcCountdownSentence(-days, -hours, -mins, -secs);
}
