import Head from "next/head";
import Image from "next/image";
import * as S from "./Home.styles";
import { useEffect, useState } from "react";
import calculateWeddingCountdown from "../../../helpers/calculateTimeUntilWedding";

export default function Home() {
  const weddingDate = new Date("2022-09-04T16:00:00Z").getTime();

  const [timeLeft, setTimeLeft] = useState(
    "Calculating..."
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateWeddingCountdown(Date.now(), weddingDate));
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Head>
        <meta
          name="description"
          property="og:description"
          content="WHAT!? - Chloë and David got married! Find out more..."
        />
        <title>David &amp; Chloë</title>
      </Head>

      <S.Container>
        <S.Timer>
          <strong>{timeLeft}</strong>
        </S.Timer>
        <S.P>
          Welcome to our wedding website! This site was to help guests with
          finding our venue, exploring the area, and getting excited for our
          wedding. We also put a{" "}
          <a
            href="https://open.spotify.com/playlist/6R7c4LrOvXDBiGus1NWA5E?si=z-G77nkWTqi9KGQSTxiu8A"
            target="_blank"
            rel="noopener noreferrer"
          >
            Spotify playlist
          </a>{" "}
          together of our favourite songs to help guests get in the mood on your
          travels, specifically on Sunday 4<sup>th</sup> September 2022 🎧❤️
        </S.P>
        <S.ImageContainer>
          <Image
            src="v1619560671/davidchloe/David_Chloe_wxq6mq.jpg"
            alt="Picture of David and Chloë"
            width={1200}
            height={1600}
            loading="eager"
            sizes="100vw"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </S.ImageContainer>
      </S.Container>
    </>
  );
}
